import React from 'react'
import { Grid, withStyles, Hidden, withWidth } from '@material-ui/core'
import PostImage from './PostImage'

const styles = themes => ({
  root: {
    overflow: 'hidden',
    [themes.breakpoints.down('xs')]: {
      marginBottom: '32px',
    },
  },
  row: {
    minHeight: '70vh',
  },
  longRow: {
    minHeight: '80vh',
  },
})

const renderImage = (post, isFirst = false) => {
  return post ? <PostImage post={post.node} clickable top={isFirst} /> : <></>
}

const renderSingleImage = (post, classes, isFirst = false) => {
  return (
    <Grid item md xs={12} className={isFirst ? classes.longRow : classes.row}>
      {renderImage(post, isFirst)}
    </Grid>
  )
}

const renderStackedImages = (top, bottom, classes, isFirst = false) => {
  return (
    <>
      <Hidden smDown>
        <Grid
          container
          item
          spacing={24}
          md={4}
          lg={4}
          alignItems="stretch"
          direction="column"
        >
          <Grid item md>
            {renderImage(top, isFirst)}
          </Grid>
          <Grid item md>
            {renderImage(bottom)}
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid item xs={12} className={classes.row}>
          {renderImage(top, isFirst)}
        </Grid>
        <Grid item xs={12} className={classes.row}>
          {renderImage(bottom)}
        </Grid>
      </Hidden>
    </>
  )
}

const renderSoloImage = (post, classes, isFirst = false) => {
  return (
    <Grid item xs className={classes.row}>
      {renderImage(post, isFirst)}
    </Grid>
  )
}

const renderDoubleImage = (left, right, classes, isFirst = false) => {
  return (
    <>
      <Grid item xs={12} sm className={classes.row}>
        {renderImage(left, isFirst)}
      </Grid>
      <Grid item xs={12} sm className={classes.row}>
        {renderImage(right)}
      </Grid>
    </>
  )
}

const renderRows = (posts, category, width, classes) => {
  if (category) {
    posts = posts.filter(v => {
      return v.node.category === category
    })
  }
  const rows = posts.length / 3
  const rowItems = []
  for (let i = 0; i < rows; i++) {
    let first, second
    const pIndex = i * 3
    const isFirst = i === 0
    const count = posts.length - pIndex
    if (i % 2 === 0) {
      if (count >= 3) {
        first = renderSingleImage(posts[pIndex], classes, isFirst)
        second = renderStackedImages(
          posts[pIndex + 1],
          posts[pIndex + 2],
          classes,
          isFirst
        )
      } else if (count === 2) {
        first = renderDoubleImage(
          posts[pIndex],
          posts[pIndex + 1],
          classes,
          isFirst
        )
        second = null
      } else {
        first = renderSoloImage(posts[pIndex], classes, isFirst)
        second = null
      }
    } else {
      if (count >= 3) {
        first = renderStackedImages(posts[pIndex], posts[pIndex + 1], classes)
        second = renderSingleImage(posts[pIndex + 2], classes)
      } else if (count === 2) {
        first = renderDoubleImage(posts[pIndex], posts[pIndex + 1], classes)
        second = null
      } else {
        first = renderSoloImage(posts[pIndex], classes)
        second = null
      }
    }
    console.log(width)
    rowItems.push(
      <Grid
        className={classes.row}
        key={i}
        container
        spacing={width !== 'xs' ? 24 : 8}
        alignItems="stretch"
        style={{ minHeight: '1000px' }}
      >
        {first}
        {second}
      </Grid>
    )
  }
  return rowItems
}

const Feed = props => {
  const { posts, classes, width } = props
  const category = null
  return (
    <div className={classes.root}>
      {renderRows(posts, category, width, classes)}
    </div>
  )
}

export default withStyles(styles)(withWidth()(Feed))
